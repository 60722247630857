import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";

import "./Gallery.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { blogData } from "../../data/blogData";
import MetaTags from "react-meta-tags";
import photo from "../../images/logo.png";

const GalleryMain = () => {
  const [numberOfData, setNumberOfData] = useState(6);
  const [displayData, setDisplayData] = useState([]);

  const handleGetMoreData = () => {
    setNumberOfData(numberOfData + 6);
  };

  const handleSetDisplayData = () => {
    setDisplayData(blogData.slice(0, numberOfData));
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    handleSetDisplayData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfData]);

  const handleModal = (img) => (
    <div
      className="modal fade"
      id={"modal" + img.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img src={img.src} className="img-responsive img-fluid" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );

  const handleMoreModal = (images) => (
    <div
      className="modal fade"
      id={"moreModal" + images.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body" style={{ width: "100%" }}>
            <Carousel showArrows={true} infiniteLoop selectedItem={5}>
              {images.photos.map((image) => (
                <img key={image.id} src={image.src} alt=".." />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="gallery-container px-2">
      <MetaTags>
        <title>Gallery</title>
        <meta name="description" content="Our Story in Pictures" />
        <meta property="og:title" content="Gallery" />
        <meta property="og:image" content={photo} />
      </MetaTags>
      {/* <h2
        data-aos="zoom-in-right"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1000"
        className=" text-center "
      >
        Our Impact Story
      </h2> */}

      <div className="row">
        {/* <div
          className="video-player"
          data-aos="zoom-in-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="3000"
        >
          <div></div>
          <video
            controlsList="nodownload"
            onContextMenu={(e) => e.preventDefault()}
            controls
            loop
            src={impact_story}
          >
            Your browser does not support this video. Use Chrome browser
          </video>
        </div> */}
      </div>

      <section className="gallery">
        <div id="image-gallery">
          <div className="row p-0 gy-5  justify-content-center">
            {displayData.length === 0 ? (
              <div
                className="text-center"
                style={{
                  height: "100vh",
                }}
              >
                Loading...
              </div>
            ) : (
              displayData
                .filter((item) => item.photos.length > 0)
                .map((item) => (
                  <div key={item.id} className="col-lg-6 col-12">
                    <div className="row p-0 gx-3 gy-3 box-shadow">
                      {item.photos.length > 0 &&
                        item.photos.slice(0, 5).map((img) => (
                          <div
                            key={img.id}
                            className="col-md-4  column-height col-6"
                          >
                            <div
                              // data-bs-toggle="modal"
                              // data-bs-target={`#modal` + img.id}
                              className="modal-img "
                            >
                              <div
                                className="img-wrapper "
                                data-bs-toggle="modal"
                                data-bs-target={`#modal` + img.id}
                              >
                                <img
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover",
                                    background: "rgba(48, 46, 46, 0.5)",
                                  }}
                                  src={img.src}
                                  className="img-responsive img-fluid "
                                  alt="..."
                                />
                              </div>
                            </div>
                            {handleModal(img)}
                          </div>
                        ))}
                      {item.photos.slice(5).length > 0 && (
                        <div className="col-md-4 column-height col-6">
                          <div
                            // data-bs-toggle="modal"
                            // data-bs-target={`#moreModal` + item.id}
                            className="justify-content-center"
                          >
                            <div
                              className="img-wrapper  justify-content-center "
                              data-bs-toggle="modal"
                              data-bs-target={`#moreModal` + item.id}
                              style={{
                                background: "rgba(48, 46, 46, 0.5)",
                                backgroundImage: `url(${item.photos[5].src})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                cursor: "pointer",
                                height: "120px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "30px",
                                  color: "white",
                                  backdropFilter: "brightness(30%)",
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  className="text-center"
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  +{item.photos.slice(5).length}
                                </p>
                              </div>
                            </div>
                          </div>
                          {handleMoreModal(item)}
                        </div>
                      )}
                      <p> {item.gallery_title} </p>
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </section>
      {displayData.length > 0 && displayData.length !== blogData.length && (
        <div className="d-flex justify-content-center mb-5">
          <button
            type="click"
            onClick={handleGetMoreData}
            className="btn btn-success"
          >
            {" "}
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default GalleryMain;
