import React from "react";
import { Card } from "antd";
import "./Team.css";
import "aos/dist/aos.css";
import { team_photos, transitionImages } from "../ImagesData";
import MetaTags from "react-meta-tags";
import photo from "../../images/logo.png";
import { Carousel } from "react-responsive-carousel";
const { Meta } = Card;

const TeamMain = () => {
  const handleModal = (img) => (
    <div
      className="modal fade"
      id={"modal" + img.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img src={img.src} className="img-responsive img-fluid" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );

  const handleMoreModal = (images) => (
    <div
      className="modal fade"
      id={"moreModal" + images.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body" style={{ width: "100%" }}>
            <Carousel showArrows={true} infiniteLoop selectedItem={5}>
              {images.photos.map((image) => (
                <img key={image.id} src={image.src} alt=".." />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <MetaTags>
        <title>Our Team</title>
        <meta name="description" content="Meet our Team" />
        <meta property="og:title" content="Our Team" />
        <meta property="og:image" content={photo} />
      </MetaTags>
      <div className="row py-3 ">
        <div className="col-md-12 col-md-3">
          <h1
            className="text-center bottom-line "
            style={{ fontSize: "1.7rem" }}
          >
            Meet our Team
          </h1>
        </div>
      </div>

      <div className="row justify-content-center">
        {team_photos.map((image) => (
          <div
            key={image.id}
            className="col-lg-4 col-md-6 col-12 my-3 d-flex justify-content-center px-0"
          >
            <Card
              hoverable
              cover={
                <img
                  alt="example"
                  src={image.src}
                  style={{
                    padding: "1px",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    background: "rgba(48, 46, 46, 0.5)",
                  }}
                />
              }
            >
              <Meta title={image.title} description={image.name} />
            </Card>
          </div>
        ))}
      </div>

      <section className="row px-3 gy-4 my-2">
        <div className="col-md-6 ">
          <div className="box-shadow">
            <h3 className="text-center my-3 py-3">
              Transition To New Leadership For The Agency{" "}
            </h3>
            <p
              data-aos="zoom-in-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              className="text-center mb-3"
            >
              This event took place in June 2021, when the Executive Governor,
              Rt. Hon. (Dr) Simon Bako Lalong KSGG announced Bomkam Ali Wuyep as
              the new Director General and Secretary to the Board Plateau State
              Microfinance Development Agency (PLASMIDA)
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row p-0  justify-content-center">
            <div className="box-shadow">
              <div className="row p-0 gx-3 gy-3 ">
                {transitionImages.photos.slice(0, 5).map((img) => (
                  <div key={img.id} className="col-md-4 col-12  column-height">
                    <div style={{ height: "100%", width: "100%" }}>
                      <div
                        className="img-wrapper"
                        data-bs-toggle="modal"
                        data-bs-target={`#modal` + img.id}
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            background: "rgba(48, 46, 46, 0.5)",
                          }}
                          src={img.src}
                          className="img-responsive img-fluid "
                          alt="..."
                        />
                      </div>
                    </div>
                    {handleModal(img)}
                  </div>
                ))}

                {transitionImages.photos.slice(2).length > 0 && (
                  <div className="col-lg-4 col-md-4  col-12 column-height">
                    <div className="justify-content-center">
                      <div
                        className="img-wrapper  justify-content-center "
                        data-bs-toggle="modal"
                        data-bs-target={`#moreModal` + transitionImages.id}
                        style={{
                          background: "rgba(48, 46, 46, 0.5)",
                          backgroundImage: `url(${transitionImages.photos[5].src})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          cursor: "pointer",
                          height: "120px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "30px",
                            color: "white",
                            backdropFilter: "brightness(30%)",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            className="text-center"
                            style={{
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            +{transitionImages.photos.slice(5).length}
                          </p>
                        </div>
                      </div>
                    </div>
                    {handleMoreModal(transitionImages)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TeamMain;
