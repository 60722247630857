import React from "react";
import { Link } from "react-router-dom";
import { blogData } from "../../data/blogData";
import "./news.css";

const News = () => {
  return (
    <ul className="news px-4">
      <h1 className="text-center " style={{ fontSize: "1.7rem" }}>
        {" "}
        Recent Posts
      </h1>
      {blogData.slice(0, 3).map((post) => (
        <li key={post.id} className="py-1 ">
          <Link to={`blog/${post.id}`}> {post.title} </Link>
        </li>
      ))}
    </ul>
  );
};

export default News;
