import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./blog.css";
import photo from "../../images/logo.png";
import { blogData } from "../../data/blogData";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  const [numberOfData, setNumberOfData] = useState(6);
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    handleSetDisplayData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfData]);

  const handleGetMoreData = () => {
    try {
      setNumberOfData(numberOfData + 6);
    } catch (error) {
      console.log("error", error.message);
    }
  };

  const handleSetDisplayData = () => {
    setDisplayData(blogData.slice(0, numberOfData));
  };

  const handleModal = (img) => (
    <div
      className="modal fade"
      id={"modal" + img.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img src={img.src} className="img-responsive img-fluid" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );

  const handleMoreModal = (images) => (
    <div
      className="modal fade"
      id={"moreModal" + images.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body" style={{ width: "100%" }}>
            <Carousel showArrows={true} infiniteLoop selectedItem={2}>
              {images.photos.map((image) => (
                <img key={image.id} src={image.src} alt=".." />
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="px-2">
      {displayData.length > 0 && (
        <Helmet>
          <title>Blog Posts</title>
          <meta name="description" content="Our Blog" />
          <meta property="og:title" content="Blog Post" />
          <meta property="og:image" content={photo} />
          <link rel="canonical" type="" href="/blog" />
        </Helmet>
      )}

      <div className="row p-0 gy-5 justify-content-center mb-5 ">
        {displayData.length === 0 ? (
          <div
            className="text-center"
            style={{
              height: "100vh",
            }}
          >
            Loading...
          </div>
        ) : (
          displayData.map((post) => (
            <div className="col-lg-6 col-12">
              <div key={post.id} className="row  p-0 gx-3  gy-3  box-shadow">
                <div>
                  <h5
                    data-aos="zoom-in-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                    className="text-center  mb-2 py-4"
                  >
                    {post.title}
                  </h5>
                  <p
                    data-aos="zoom-in-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                  >
                    {post.description.desc.length > 100
                      ? post.description.desc
                          .split(" ")
                          .slice(0, 30)
                          .join(" ") + "..."
                      : post.description.desc}
                  </p>
                </div>

                <section className="gallery">
                  <div className="readmore-btn">
                    <h4
                      data-aos="zoom-in-right"
                      data-aos-easing="ease-out-cubic"
                      // data-aos-duration="100"
                      className="text-center more"
                    >
                      <Link to={`${post.id}`}> More {">>"} </Link>{" "}
                    </h4>
                  </div>
                  <h4
                    data-aos="zoom-in-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                    className="text-center p-0 pt-4"
                  >
                    Story in pictures
                  </h4>
                  <div id="image-gallery">
                    <div className="row gy-3  programs-div">
                      {post.photos.slice(0, 2).map((img) => (
                        <div
                          key={img.id}
                          className="col-lg-4 col-md-4 col-12 column-height "
                        >
                          <div
                            data-bs-toggle="modal"
                            data-bs-target={`#modal` + img.id}
                            style={{ width: "100%", height: "100%" }}
                          >
                            <div
                              className="img-wrapper"
                              data-bs-toggle="modal"
                              data-bs-target={`#modal` + img.id}
                            >
                              <img
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                                src={img.src}
                                className="img-responsive img-fluid "
                                alt="..."
                              />
                            </div>
                          </div>
                          {handleModal(img)}
                        </div>
                      ))}
                      {post.photos.slice(2).length > 0 && (
                        <div className="col-lg-4 col-md-4  col-12 column-height">
                          <div className="justify-content-center">
                            <div
                              className="img-wrapper  justify-content-center "
                              data-bs-toggle="modal"
                              data-bs-target={`#moreModal` + post.id}
                              style={{
                                background: "rgba(48, 46, 46, 0.5)",
                                backgroundImage: `url(${post.photos[2].src})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                cursor: "pointer",
                                height: "120px",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "30px",
                                  color: "white",
                                  backdropFilter: "brightness(30%)",
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  className="text-center"
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  +{post.photos.slice(3).length}
                                </p>
                              </div>
                            </div>
                          </div>
                          {handleMoreModal(post)}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          ))
        )}
      </div>
      {displayData.length > 0 && displayData.length !== blogData.length && (
        <div className="d-flex justify-content-center mb-5">
          <button
            type="click"
            onClick={handleGetMoreData}
            className="btn btn-success"
          >
            {" "}
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default Blog;
