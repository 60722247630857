import React, { useEffect } from "react";
import dgPhoto from "../../images/dg.jpg";
import "./About.css";
import AOS from "aos";
import "aos/dist/aos.css";
const AboutMain = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const values = [
    {
      id: 1,
      title: "Our Mission",
      details:
        "To develop the capacity of Micro Small and Medium Enterprises in the State towards job creation, and poverty reduction.",
    },
    {
      id: 2,
      title: " Our Vision",
      details:
        " To be a world class Agency that is passionate and efficient in the    promotion and development of Micro Small and Medium Enterprises.",
    },
    {
      id: 3,
      title: "Core Values",
      details: "Team work, Resourcefulness, Integrity, Passion, (T R I P)",
    },
    {
      id: 4,
      title: "Product and Services",
      details: " Coaching, Mentoring, Training, Linkages, Consultancy",
    },
  ];
  return (
    <div className="py-3" id="about">
      <div className="row">
        <h1 className="text-center py-3 mt-2" style={{ fontSize: "1.7rem" }}>
          Welcome to PLASMIDA
        </h1>

        <div className="col-lg-4  col-12 py-3 d-flex justify-content-center align-items-center">
          <div className=" photo-div " style={{ height: "100%" }}>
            <img
              src={dgPhoto}
              className="img-fluid "
              alt="..."
              style={{
                height: "100%",
                objectFit: "cover",
                background: "rgba(48, 46, 46, 0.5)",
              }}
            />
          </div>
        </div>
        <div className="col-lg-8  col-12 py-3  d-flex justify-content-center align-items-center  ">
          <div className=" text-div about-shadow p-3">
            <p className="text-start">
              The commitment and determination of the Governor to move the state
              out of economic stagnation to a commercial hub of the nation, was
              demonstrated through the establishment of the Plateau State
              Microfinance Development Agency(PLASMIDA) in 2016 when the bill
              establishing the Agency was signed into law by the Executive
              Governor of Plateau State.
            </p>
            <p className="text-start">
              The Agency is strategically positioned, to assist in the human
              capital development needs of the existing and would be Micro,
              Small and Medium Enterprises (MSME’s) operators, provide linkage
              services in terms of access to finance, market information,
              quality control services to the MSME’s, source, initiate and
              coordinate bilateral or multilateral technical assistance or
              partnership programmes with local and international organizations
              and foundations.
            </p>
            <p className="text-start">
              Plateau State Microfinance Development Agency is set to put in
              place deliberate platforms to facilitate government intervention
              that would ensure that disadvantaged categories of people in the
              state benefit from any intervention programme that will improve
              household income, attract people-oriented development projects,
              implement and coordinate MSME policies and programmes in the
              State.
            </p>
            <p className="text-start">
              Ensure measurable improvement to the quality of life of the MSME
              in the State by ensuring that business development support
              services necessary to impact positively on the business of the
              poor and micro entrepreneurs are developed through quality
              education and confidence building programmes.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid px-0">
        <div className="row gy-2 ">
          {values.map((value) => (
            <div key={value.id} className=" col-md-6 ">
              <div className="value-wrapper my-3 px-3">
                <h4 className="text-center pt-2">{value.title}</h4>
                <p className="text-center px-"> {value.details} </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutMain;
