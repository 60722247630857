import nrc from "../images/partners/nrc.png";
import giz from "../images/partners/giz-logo.jpg";
import jbs from "../images/partners/jbs.jpeg";
import pictda from "../images/partners/pictda.png";
import pyc from "../images/partners/pyc.png";
import tearfund from "../images/partners/tearfund.png";
import worldbank from "../images/partners/worldbank.svg";
import nirsal from "../images/partners/nirsal.png";

import training1 from "../images/trainings/training1.jpg";
import training2 from "../images/trainings/training2.jpg";
import training3 from "../images/trainings/training3.jpg";
import training4 from "../images/trainings/training4.jpg";
import training5 from "../images/trainings/training5.jpg";
import training6 from "../images/trainings/training6.jpg";

import coaching1 from "../images/trainings/coaching1.jpg";
import coaching2 from "../images/trainings/coaching2.jpg";
import coaching3 from "../images/trainings/coaching3.jpg";
import coaching4 from "../images/trainings/coaching4.jpg";
import coaching5 from "../images/trainings/coaching5.jpg";
import coaching6 from "../images/trainings/coaching6.jpg";

import consultancy1 from "../images/trainings/consultancy1.jpg";
import consultancy2 from "../images/trainings/consultancy2.jpg";
import consultancy3 from "../images/trainings/consultancy3.jpg";
import consultancy4 from "../images/trainings/consultancy4.jpg";
import consultancy5 from "../images/trainings/consultancy5.jpg";
import consultancy6 from "../images/trainings/consultancy6.jpg";

import linkage1 from "../images/linkages/geep.png";
import linkage2 from "../images/linkages/farmer_moni.jpeg";
import linkage3 from "../images/linkages/market_moni.jpeg";

import micro1 from "../images/businesses/micro1.jpg";
import micro2 from "../images/businesses/micro2.jpg";
import micro3 from "../images/businesses/micro3.jpg";
import micro4 from "../images/businesses/micro4.jpg";
import micro5 from "../images/businesses/micro5.jpg";

import small1 from "../images/businesses/small1.jpg";
import small2 from "../images/businesses/small2.jpg";
import small3 from "../images/businesses/small3.jpg";
import small4 from "../images/businesses/small4.jpg";
import small5 from "../images/businesses/small5.jpg";

import medium1 from "../images/businesses/medium1.jpg";
import medium2 from "../images/businesses/medium2.jpg";
import medium3 from "../images/businesses/medium3.jpg";
import medium4 from "../images/businesses/medium4.jpg";
import medium5 from "../images/businesses/medium5.jpg";

export const partnersData = [
  { id: 1, src: worldbank },
  { id: 2, src: giz },
  { id: 3, src: pictda },
  { id: 7, src: nirsal },
  { id: 4, src: jbs },
  { id: 5, src: pyc },
  { id: 6, src: tearfund },
  { id: 8, src: nrc },
];

export const offerData = [
  {
    id: 1,
    title: "Trainings",
    photos: [
      { id: "1", src: training1 },
      { id: "2", src: training2 },
      { id: "3", src: training3 },
      { id: "31", src: training4 },
      { id: "312", src: training5 },
      { id: "312", src: training6 },
    ],
  },
  {
    id: 2,
    title: "Linkages",
    photos: [
      { id: "4", src: linkage1 },
      { id: "5", src: linkage2 },
      { id: "6", src: linkage3 },
    ],
  },
  {
    id: 3,
    title: "Coaching/Mentorship",
    photos: [
      { id: "7", src: coaching1 },
      { id: "8", src: coaching2 },
      { id: "9", src: coaching3 },
      { id: "99", src: coaching4 },
      { id: "990", src: coaching5 },
      { id: "990", src: coaching6 },
    ],
  },
  {
    id: 4,
    title: "Consultancy",
    photos: [
      { id: "10", src: consultancy1 },
      { id: "11", src: consultancy2 },
      { id: "12", src: consultancy3 },
      { id: "13", src: consultancy4 },
      { id: "134", src: consultancy5 },
      { id: "134", src: consultancy6 },
    ],
  },
];

export const targerMarketData = [
  {
    id: 1,
    title: "Micro Enterprises",
    photos: [
      { id: "1", src: micro1 },
      { id: "2", src: micro2 },
      { id: "3", src: micro3 },
      { id: "4", src: micro4 },
      { id: "5", src: micro5 },
    ],
  },
  {
    id: 2,
    title: "Small Enterprises",
    photos: [
      { id: "11", src: small1 },
      { id: "12", src: small2 },
      { id: "13", src: small3 },
      { id: "14", src: small4 },
      { id: "15", src: small5 },
    ],
  },
  {
    id: 3,
    title: "Medium Enterprises",
    photos: [
      { id: "7", src: medium1 },
      { id: "8", src: medium2 },
      { id: "6", src: medium3 },
      { id: "9", src: medium4 },
      { id: "10", src: medium5 },
    ],
  },
];
