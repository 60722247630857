import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./offering.css";
import { offerData } from "../../data/helpers";

const Offering = () => {
  return (
    <div className="container-fluid ">
      <h3 className="text-center mt-5">What We Offer</h3>
      <div className="row justify-content-center text-center gy-3 gx-4 offer">
        {offerData.map((offer) => (
          <div
            key={offer.id}
            className="col-xl-3 col-lg-3 d-flex justify-content-center col-md-4 col-6"
          >
            <div class="card border-0 w-100  ">
              <div className="offer-div">
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  interval={8000}
                  showThumbs={false}
                  showStatus={false}
                  showArrows={false}
                  stopOnHover={false}
                  showIndicators={false}
                  transitionTime={1500}
                >
                  {offer.photos.map((img) => (
                    <div key={img.id}>
                      <img
                        src={img.src}
                        alt="training"
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                  ))}
                </Carousel>{" "}
              </div>
              <div class="card-body ">
                <p class="card-text                                                                                              ">
                  {offer.title}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Offering;
