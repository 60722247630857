import trans1 from "../images/transition_to_new_leadership/transition1.jpg";
import trans2 from "../images/transition_to_new_leadership/transition2.jpg";
import trans3 from "../images/transition_to_new_leadership/transition3.jpg";
import trans4 from "../images/transition_to_new_leadership/transition4.jpg";
import trans5 from "../images/transition_to_new_leadership/transition5.jpg";
import trans6 from "../images/transition_to_new_leadership/transition6.jpg";
import trans7 from "../images/transition_to_new_leadership/transition7.jpg";
import trans8 from "../images/transition_to_new_leadership/transition8.jpg";
import trans9 from "../images/transition_to_new_leadership/transition9.jpg";
import trans10 from "../images/transition_to_new_leadership/transition10.jpg";
import trans11 from "../images/transition_to_new_leadership/transition11.jpg";
import trans12 from "../images/transition_to_new_leadership/transition12.jpg";
import trans13 from "../images/transition_to_new_leadership/transition13.jpg";
import trans14 from "../images/transition_to_new_leadership/transition14.jpg";
import trans15 from "../images/transition_to_new_leadership/transition15.jpg";
import trans16 from "../images/transition_to_new_leadership/transition16.jpg";
import trans17 from "../images/transition_to_new_leadership/transition17.jpg";
import trans18 from "../images/transition_to_new_leadership/transition18.jpg";
import trans19 from "../images/transition_to_new_leadership/transition19.jpg";
import trans20 from "../images/transition_to_new_leadership/transition20.jpg";
import trans21 from "../images/transition_to_new_leadership/transition21.jpg";
import trans22 from "../images/transition_to_new_leadership/transition22.jpg";

import dg from "../images/team_leads/dg.jpg";
import accountant from "../images/team_leads/accountant.png";
import sunkur from "../images/team_leads/sunkur.png";
import azi from "../images/team_leads/azi.jpg";
import jackse from "../images/team_leads/jackse.jpg";
import jenni from "../images/team_leads/jenni.png";
import karami from "../images/team_leads/karami.png";
import grace from "../images/team_leads/grace.png";
import nansel from "../images/team_leads/nansel.jpg";
import sam from "../images/team_leads/sam.jpg";
import victor from "../images/team_leads/victor.jpg";
import auditor from "../images/team_leads/auditor.jpg";

export const transitionImages = {
  id: "trans",
  photos: [
    { id: "trans1", src: trans1 },
    { id: "trans2", src: trans2 },
    { id: "trans3", src: trans3 },
    { id: "trans4", src: trans4 },
    { id: "trans5", src: trans5 },
    { id: "trans6", src: trans6 },
    { id: "trans7", src: trans7 },
    { id: "trans8", src: trans8 },
    { id: "trans9", src: trans9 },
    { id: "trans10", src: trans10 },
    { id: "trans11", src: trans11 },
    { id: "trans12", src: trans12 },
    { id: "trans13", src: trans13 },
    { id: "trans14", src: trans14 },
    { id: "trans15", src: trans15 },
    { id: "trans16", src: trans16 },
    { id: "trans17", src: trans17 },
    { id: "trans18", src: trans18 },
    { id: "trans19", src: trans19 },
    { id: "trans20", src: trans20 },
    { id: "trans21", src: trans21 },
    { id: "trans22", src: trans22 },
  ],
};

export const team_photos = [
  { id: 1, src: dg, name: "Bomkam Ali Wuyep", title: "Director General" },
  {
    id: 2,
    src: sam,
    name: "Phillip Samme",
    title: "Director Monitoring & Evaluation",
  },
  {
    id: 4,
    src: victor,
    name: "Victor Diyal",
    title: "Director Research & Training",
  },
  { id: 3, src: jenni, name: "Jenifer Turay", title: "Gender Specialist" },

  {
    id: 9,
    src: karami,
    name: "Samson Karami",
    title: "Director Partnership & Linkages",
  },

  {
    id: 5,
    src: jackse,
    name: "Jackse Le'an",
    title: "Director Admin & Finance",
  },
  {
    id: 6,
    src: accountant,
    name: "Laurence Damulen",
    title: "Director Finance",
  },
  {
    id: 12,
    src: auditor,
    name: "Ibrahim Sambo",
    title: "Director Internal Audit",
  },
  { id: 7, src: grace, name: "Grace Nanle", title: "Head MSMEs" },
  { id: 8, src: nansel, name: "Nansel Danboyi", title: "Head ICT" },
  { id: 10, src: sunkur, name: "Joseph Sunkur", title: "Head Procurement" },
  { id: 11, src: azi, name: "Azi Itse", title: "Environmental Specialist" },
];
