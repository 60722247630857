import React from "react";
import { partnersData } from "../../data/helpers";
import "./partners.css";

const Partners = () => {
  return (
    <div className="partners container py-3">
      <h3 class="text-center"> Our Partners</h3>
      <div className="slide-track py-3">
        {partnersData.map((partner) => (
          <div key={partner.id} className="slide">
            <img src={partner.src} height="70" width="250" alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
