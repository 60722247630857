import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Footer = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <footer className="container-fluid footer px-3 py-3 ">
      <div className="rows">
        <ul
          // data-aos="zoom-in-right"
          // data-aos-easing="ease-out-cubic"
          // data-aos-duration="1000"
          className="list-group list-group-horizontal justify-content-center flex-wrap"
        >
          <li className="list-group-item">
            {" "}
            <Link to="/">Home</Link>{" "}
          </li>
          <li className="list-group-item">
            {" "}
            <Link to="/about">About</Link>{" "}
          </li>
          <li className="list-group-item">
            {" "}
            <Link to="/organogram">Organogram</Link>{" "}
          </li>
          <li className="list-group-item">
            {" "}
            <Link to="/gallery">Gallery</Link>{" "}
          </li>
          <li className="list-group-item">
            {" "}
            <Link to="/programs">Projects</Link>{" "}
          </li>
          <li className="list-group-item">
            {" "}
            <Link to="/blog">Blog</Link>{" "}
          </li>
          <li className="list-group-item">
            {" "}
            <Link to="/contact">Contact</Link>{" "}
          </li>
        </ul>

        <ul
          // data-aos="zoom-in-right"
          // data-aos-easing="ease-out-cubic"
          // data-aos-duration="1000"
          className="list-group list-group-horizontal justify-content-center flex-wrap py-3"
        >
          <li className="list-group-item">
            {" "}
            {/* https://web.facebook.com/plasmida.plasmida.9 */}
            <a
              href="https://web.facebook.com/plasmida.plasmida.9"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i> Facebook
            </a>{" "}
          </li>
          <li className="list-group-item">
            {" "}
            <a
              href="https://twitter.com/plasmeda"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i> Twitter
            </a>{" "}
          </li>
          {/* <li className="list-group-item">
            {" "}
            <Link to="#">
              {" "}
              <i className="fab fa-instagram"></i> Instagram
            </Link>
          </li> */}
        </ul>
        <ul
          // data-aos="zoom-in-right"
          // data-aos-easing="ease-out-cubic"
          // data-aos-duration="1000"
          className=" list-group list-group-horizontal justify-content-center flex-wrap "
        >
          <li className="list-group-item text-white">
            {" "}
            &copy;2022 - PLASMIDA | All Rights Reserved
          </li>
        </ul>
        <ul
          // data-aos="zoom-in-right"
          // data-aos-easing="ease-out-cubic"
          // data-aos-duration="1000"
          className="list-group list-group-horizontal justify-content-center flex-wrap py-3"
        >
          <li className="list-group-item text-white">
            {" "}
            <i className="fas fa-map-marker-alt"></i> Plot 780, Liberty
            Boulevard, Jos, Plateau State
          </li>

          <li className="list-group-item text-white">
            {" "}
            <i className="fas fa-envelope"></i> plasmida@plateaustate.gov.ng
          </li>
          <li className="list-group-item text-white">
            {" "}
            <i className="fas fa-phone"></i> +2349077109179 | +2349167825539
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
