import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Program.css";
import { programData } from "../../data/programData";
import photo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Programs = () => {
  const [numberOfData, setNumberOfData] = useState(6);
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    handleSetDisplayData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfData]);

  const handleGetMoreData = () => {
    setNumberOfData(numberOfData + 6);
  };

  const handleSetDisplayData = () => {
    setDisplayData(programData.slice(0, numberOfData));
  };

  return (
    <div className="px-2">
      <Helmet>
        <title>Programs</title>
        <meta name="description" content="Our Programs" />
        <meta property="og:title" content="Program" />
        <meta property="og:image" content={photo} />
        <link rel="canonical" type="" href="/programs" />
      </Helmet>

      <div className="row p-0 gy-5  justify-content-center pb-5">
        {displayData.length === 0 ? (
          <div
            className="text-center"
            style={{
              height: "100vh",
            }}
          >
            Loading...
          </div>
        ) : (
          displayData.map((program) => (
            <div key={program.id} className="col-lg-6 col-12">
              <div className="row p-0 gx-3 gy-3 box-shadow">
                <div>
                  <h5
                    data-aos="zoom-in-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                    className="text-center mb-2 py-4"
                  >
                    {program.title}
                  </h5>
                  <p
                    data-aos="zoom-in-right"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="1000"
                  >
                    {program.description.desc.length > 100
                      ? program.description.desc
                          .split(" ")
                          .slice(0, 50)
                          .join(" ") + "..."
                      : program.description.desc}
                  </p>
                </div>

                <section className="gallery">
                  <div className="readmore-btn">
                    <h4 className="text-center more">
                      <Link to={`${program.id}`}> More {">>"} </Link>{" "}
                    </h4>
                  </div>
                </section>
              </div>
            </div>
          ))
        )}
      </div>
      {displayData.length > 0 && displayData.length !== programData.length && (
        <div className="d-flex justify-content-center mb-5">
          <button
            type="click"
            onClick={handleGetMoreData}
            className="btn btn-success"
          >
            {" "}
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default Programs;
