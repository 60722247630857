import React from "react";
import organogram from "../../images/organogram.png";
import "./Organogram.css";
import "aos/dist/aos.css";
import photo from "../../images/logo.png";
import { Helmet } from "react-helmet-async";

const Organogram = () => {
  return (
    <div className="container organaogram ">
      <Helmet>
        <title>Organogram</title>
        <meta name="description" content="PLASMIDA organizational structure" />
        <meta property="og:title" content="Organogram" />
        <meta property="og:image" content={photo} />
        <link rel="canonical" type="" href="/organogram" />
      </Helmet>

      <a href="https://google.com" target="_blank" rel="noreferrer">
        Google.com
      </a>

      <div
        className="card"
        // data-aos="zoom-in-right"
        // data-aos-easing="ease-out-cubic"
        // data-aos-duration="1000"
      >
        <img
          src={organogram}
          style={{
            height: "100%",
            background: "rgba(48, 46, 46, 0.5)",
          }}
          className="card-img-top"
          alt="..."
        />
      </div>
    </div>
  );
};

export default Organogram;
