import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./target.css";
import { targerMarketData } from "../../data/helpers";

const Target = () => {
  return (
    <div className="container-fluid ">
      <h3 className="text-center p-0 mb-4 mt-5">Our Target Market</h3>
      <div className="row justify-content-center text-center target">
        {targerMarketData.map((target) => (
          <div
            key={target.id}
            className="col-xl-4 col-lg-4 d-flex                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         justify-content-center col-md-4 col-6  "
          >
            <div className="card border-0 w-100">
              <div className="target-div">
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  interval={16000}
                  showThumbs={false}
                  showStatus={false}
                  showArrows={false}
                  stopOnHover={false}
                  showIndicators={false}
                  transitionTime={1500}
                >
                  {target.photos.map((img) => (
                    <div key={img.id}>
                      <img src={img.src} alt="enterprise" />
                    </div>
                  ))}
                </Carousel>
              </div>
              <div className="card-body p-0 m-0">
                <p className="card-text ">{target.title}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Target;
