import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { programData } from "../../data/programData";
import MetaTags from "react-meta-tags";
import photo from "../../images/logo.png";

const Program = () => {
  const params = useParams();
  const [program, setProgram] = useState(null);

  const handleSetProgram = () => {
    programData.forEach((pr) => {
      pr.id === Number(params.id) && setProgram(pr);
    });
  };

  const handleModal = (img) => (
    <div
      className="modal fade"
      id={"modal" + img.id}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <img src={img.src} className="img-responsive img-fluid" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    handleSetProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{program ? program.title : "Program"}</title>
        <meta name="description" content="Our Programs" />
        <meta property="og:title" content="Program" />
        <meta property="og:image" content={photo} />
        <link rel="canonical" type="" href="/program/" />
      </MetaTags>

      {!program ? (
        <div
          style={{
            height: "100vh",
          }}
        >
          Loading...
        </div>
      ) : (
        <div
          key={program.id}
          data-aos="zoom-in-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          className="programs mb-4"
        >
          <div>
            <h3
              data-aos="zoom-in-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              className="text-center  mb-2 py-4"
            >
              {program.title && program.title}
            </h3>
            <h5>{program.description.title && program.description.title}</h5>
            <p>{program.description.desc && program.description.desc}</p>
            <p>
              {program.description.listTitle && program.description.listTitle}
            </p>
            <ul className="px-5 mb-3">
              {program.description.listItems &&
                program.description.listItems.map((item) => (
                  <li key={item.id}> {item.title} </li>
                ))}
            </ul>
            <h5>{program.description2.title && program.description2.title} </h5>
            <p>{program.description2.desc && program.description2.desc} </p>
            <p>
              {program.description2.listTitle && program.description2.listTitle}
            </p>
            <ul className="px-5 mb-3">
              {program.description2.listItems &&
                program.description2.listItems.map((item) => (
                  <li key={item.id}> {item.title} </li>
                ))}
            </ul>

            <h5>
              {" "}
              {program.description3.title && program.description3.title}{" "}
            </h5>
            <p>{program.description3.desc && program.description3.desc} </p>
            <p>
              {program.description3.listTitle && program.description3.listTitle}
            </p>
            <ul className="px-5 mb-3">
              {program.description3.listItems &&
                program.description3.listItems.map((item) => (
                  <li key={item.id}> {item.title} </li>
                ))}
            </ul>

            <h5>
              {" "}
              {program.description4.title && program.description4.title}{" "}
            </h5>
            <p>{program.description4.desc && program.description4.desc} </p>
            <p>
              {program.description4.listTitle && program.description4.listTitle}
            </p>
            <ul className="px-5 mb-3">
              {program.description4.listItems &&
                program.description4.listItems.map((item) => (
                  <li key={item.id}> {item.title} </li>
                ))}
            </ul>
            <h5>
              {" "}
              {program.description5.title && program.description5.title}{" "}
            </h5>
            <p>{program.description5.desc && program.description5.desc} </p>
            <p>
              {program.description5.listTitle && program.description5.listTitle}
            </p>
            <ul className="px-5 mb-3">
              {program.description5.listItems &&
                program.description5.listItems.map((item) => (
                  <li key={item.id}> {item.title} </li>
                ))}
            </ul>
          </div>

          <section className="gallery">
            {/* <h4
              // data-aos="zoom-in-right"
              // data-aos-easing="ease-out-cubic"
              // data-aos-duration="1000"
              className="text-center program-title"
            >
              Story in pictures
            </h4> */}
            <div id="image-gallery">
              <div className="row justify-content-center programs-div">
                {program.photos &&
                  program.photos.map((img) => (
                    <>
                      <div
                        key={img.id}
                        // data-aos="zoom-in-right"
                        // data-aos-easing="ease-out-cubic"
                        // data-aos-duration="1000"
                        data-bs-toggle="modal"
                        data-bs-target={`#modal` + img.id}
                        className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image-div "
                      >
                        <div
                          className="img-wrapper"
                          data-bs-toggle="modal"
                          data-bs-target={`#modal` + img.id}
                        >
                          <img
                            src={img.src}
                            className="img-fluid image"
                            alt="..."
                          />
                        </div>
                      </div>

                      {handleModal(img)}
                    </>
                  ))}
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default Program;
